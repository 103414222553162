<template>
  <div class="fill-height">
    <v-container class="offers-page pb-15 mb-15">
      <h1 class="font-weight-light main-h2 mt-5 mb-5">{{ $t("Offers") }}</h1>

      <v-row no-gutters class="mb-10 offer-search">
        <v-col cols="12" md="8">
          <v-text-field
            :label="$t('SearchOffers')"
            outlined
            single-line
            v-model="search.text"
            hide-details
            @keydown.enter="doSearch"
            background-color="white"
            :loading="is_searching"
            style="border-radius: 0"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-autocomplete
            :items="category_items"
            v-model="search.category_id"
            hide-details
            single-line
            width="200"
            item-value="id"
            style="border-radius: 0"
            item-text="title"
            outlined
            :label="$t('Categories')"
            class="category-info"
          >
            <template v-slot:item="data">
              <template v-if="typeof data.item !== 'object'">
                <v-list-item-content v-text="data.item"></v-list-item-content>
              </template>
              <template v-else>
                <v-list-item-content>
                  <v-list-item-title
                    v-html="data.item.title"
                  ></v-list-item-title>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>

          <!--
          <v-select
            :items="category_items"
            v-model="search.category_id"
            hide-details
            single-line
            width="200"
            item-value="id"
            style="border-radius: 0"
            item-text="title"
            outlined
            :label="$t('Categories')"
          ></v-select>-->
        </v-col>
        <v-col cols="12" md="1">
          <v-btn
            tile
            depressed
            large
            color="primary"
            @click="doSearch"
            :loading="is_searching"
            class="text-none"
            height="56px"
            >{{ $t("Search") }}</v-btn
          >
        </v-col>
      </v-row>

      <v-skeleton-loader
        :loading="offers.is_loading"
        max-width="300"
        type="card"
      >
        <v-row>
          <v-col
            xs="6"
            md="3"
            cols="6"
            v-for="item in offers.items"
            :key="item.item_url"
          >
            <v-hover v-slot:default="{ hover }">
              <v-card height="480" :elevation="hover ? 3 : 1">
                <v-card
                  flat
                  :title="$t('GoToProduct')"
                  :href="item.item_url"
                  target="_blank"
                  rel="nofollow"
                  @click="onClick(item)"
                >
                  <v-img :src="item.image_url" height="220px" contain></v-img>

                  <v-card-title>
                    <h2 class="subtitle-1 font-weight-bold offers-header">
                      {{ item.title }}
                    </h2>
                  </v-card-title>

                  <v-card-subtitle>
                    <div class="offers-description">
                      {{ item.description }}
                    </div>
                  </v-card-subtitle>
                  <v-card-actions class="text-center">
                    <div class="main-price mx-auto">
                      <b>{{ item.price }}</b
                      ><br />
                      <span v-if="item.old_price" class="old-price caption">
                        {{ item.old_price }}
                      </span>
                    </div>
                  </v-card-actions>
                </v-card>
              </v-card>
            </v-hover>
          </v-col>

          <v-col xs="12" cols="12" class="mb-12">
            <v-col
              xs="12"
              cols="12"
              v-if="unit_pages > 1 && !offers.error"
              class="text-center mt-10"
            >
              <v-pagination
                v-model="page"
                :length="unit_pages"
                :total-visible="7"
              ></v-pagination>
            </v-col>
            <v-col
              xs="12"
              cols="12"
              v-if="offers.error && !offers.is_loading"
              class="mt-4 text-center"
              >{{ offers.error }}</v-col
            >
            <v-col
              xs="12"
              cols="12"
              v-if="
                (!offers.items ||
                  (offers.items.length < 1 && !offers.is_loading)) &&
                !offers.error
              "
              class="text-center"
              >{{ $t("NoOffers") }}</v-col
            >
          </v-col>
        </v-row>
      </v-skeleton-loader>

      <v-row class="text-center caption bottom-categories mt-10 pl-5 pr-5">
        <span class="mx-auto mb-1">{{ $t("Categories") }}</span>
        <ul>
          <template v-for="item in category_items">
            <li :key="item.id" v-if="item.id > 0">
              <router-link
                :to="{ name: 'offers_category', params: { id: item.url_name } }"
                >{{ item.title }}</router-link
              >
            </li>
          </template>
        </ul>
      </v-row>
    </v-container>
  </div>
</template>
<i18n>
    {
    "en": {
    "Offers": "Offers",
    "Categories": "Categories",
    "All": "All",
    "SearchOffers": "Search products",
    "NoOffers": "No offers found!",
    "Search": "Search",
    "Page": "Page",
    "OffersDesc": "Find interesting offers from our partners",
    "OffersCatDesc": "Find interesting offers in the category, ",
    "ReadMore": "Read more",
    "GoToProduct": "Visit store"
    },
    "sv": {
    "Offers": "Erbjudanden",
    "Categories": "Kategorier",
    "All": "Alla",
    "SearchOffers": "Sök produkter",
    "NoOffers": "Inga erbjudanden kunde hittas.",
    "Search": "Sök",
    "Page": "Sida",
    "OffersDesc": "Hitta intressanta erbjudanden från våra samarbetspartners",
    "OffersCatDesc": "Hitta intressanta erbjudanden i kategorin, ",
    "ReadMore": "Läs mer om produkten",
    "GoToProduct": "Besök butiken"
    }
    }
</i18n>

<script>
import { offersService } from "../../../_services";
import categories from "../../../data/offers_categories.json";

export default {
  name: "offers",
  metaInfo() {
    return {
      title: this.getTitle(),
      meta: [
        {
          property: "og:title",
          content: this.getTitle() + " | " + process.env.VUE_APP_NAME,
          vmid: "og:title",
        },
        {
          name: "description",
          content: this.getDescription(),
          vmid: "description",
        },
        {
          property: "og:description",
          content: this.getDescription(),
          vmid: "og:description",
        },
      ],
    };
  },
  data: () => ({
    category_items: [],

    search: {
      text: "",
      category_id: 0,
    },

    offers: {
      items: [],
      error: null,
      is_loading: false,
    },
    is_searching: false,
    category_title: "",
    page: 1,
    unit_items: 0,
    unit_pages: 0,
  }),
  created: function () {
    if (process.env.VUE_APP_SHOW_OFFERS !== "true") {
      this.$router.push("/");
      return;
    }

    this.loadCategories();
    this.setFromUrl();
    this.loadOffers();
  },
  methods: {
    loadOffers() {
      var self = this;
      self.offers.is_loading = true;
      self.offers.error = null;

      offersService
        .search(self.search.category_id, self.search.text, self.page)
        .then(function (response) {
          self.offers.items = response.data.data;
          //self.offers.items = self.offers.items.concat(response.data.data);
          self.unit_pages = response.data.unit_pages;
          self.unit_items = response.data.unit_items;
          self.offers.is_loading = false;
          self.is_searching = false;
        })
        .catch(function (error) {
          self.offers.error = error;
          self.offers.is_loading = false;
          self.is_searching = false;
        });
    },
    doSearch() {
      var self = this;
      var q = this.$route.query.q;
      var c = this.$route.params.id;

      if (!q) q = "";
      if (!c) c = 0;

      if (q == self.search.text && c == this.search.category_id) return;

      this.$ga.event("Offers", "Search", q, 1);

      this.offers.items = [];
      this.page = 1;
      this.is_searching = true;

      if (this.search.category_id > 0) {
        var cat = this.getCategory(this.search.category_id);

        if (self.search.text != "")
          this.$router.push({
            name: "offers_category",
            query: { q: self.search.text },
            params: { id: cat.url_name },
          });
        else
          this.$router.push({
            name: "offers_category",
            params: { id: cat.url_name },
          });
      } else {
        if (self.search.text != "")
          this.$router.push({ name: "offers", query: { q: self.search.text } });
        else this.$router.push({ name: "offers" });
      }
    },
    loadCategories() {
      var unit = categories.length;

      if (this.category_items.length > 0) return;

      this.category_items.push({
        title: this.$t("All"),
        url: "/offers",
        id: 0,
      });

      for (var i = 0; i < unit; i++) {
        var item = categories[i];

        if (item.header) {
          this.category_items.push({ divider: true });
        } else {
          this.category_items.push({
            title: item.title,
            url: "/offers/category/" + item.url_name,
            id: item.id,
            url_name: item.url_name,
          });
        }
      }
    },
    setFromUrl() {
      this.search.category_id = 0;
      this.search.text = "";
      this.page = 1;

      this.category_title = "";

      if (this.$route.params.id) {
        var cat = null;

        if (!isNaN(this.$route.params.id)) {
          cat = this.getCategory(this.$route.params.id);

          if (cat) {
            if (this.search.text != "")
              this.$router.push({
                name: "offers_category",
                query: { q: this.search.text },
                params: { id: cat.url_name },
              });
            else
              this.$router.push({
                name: "offers_category",
                params: { id: cat.url_name },
              });
          }
        } else {
          cat = this.getCategoryByName(this.$route.params.id);
        }
        if (cat == null) {
          this.$router.push("/offers");
          return;
        }

        this.category_title = cat.title;
        this.search.category_id = cat.id;
      }

      if (this.$route.query.q) this.search.text = this.$route.query.q;
      if (this.$route.query.page) this.page = parseInt(this.$route.query.page);
    },
    getCategory(id) {
      for (var i = 0; i < this.category_items.length; i++) {
        var obj = this.category_items[i];

        if (obj.id == id) {
          return obj;
        }
      }
      return null;
    },
    getCategoryByName(name) {
      for (var i = 0; i < this.category_items.length; i++) {
        var obj = this.category_items[i];

        if (obj.url_name == name) {
          return obj;
        }
      }
      return null;
    },
    getTitle() {
      var title = "";

      if (this.page > 1) {
        title = this.$t("Page") + " " + this.page + " | ";
      }

      if (this.category_title && this.category_title != "") {
        title += this.category_title + " - ";
      }

      title += this.$t("Offers");

      return title;
    },
    getDescription() {
      if (this.category_title && this.category_title != "") {
        return this.$t("OffersCatDesc") + " " + this.category_title;
      } else {
        return this.$t("OffersDesc");
      }
    },
    onClick(item) {
      this.$ga.event("Offers", "Clicked", item.title, 1);
    },
  },
  watch: {
    $route() {
      this.setFromUrl();
      this.loadOffers();
    },
    page() {
      var self = this;
      if (self.search.text) {
        if (
          parseInt(this.$route.query.page) == self.page &&
          this.$route.query.q == self.search.text
        )
          return;
        this.$router.push({ query: { page: self.page, q: self.search.text } });
      } else {
        if (parseInt(this.$route.query.page) == self.page) return;
        this.$router.push({ query: { page: self.page } });
      }
      this.$vuetify.goTo(0);
    },
  },
};
</script>

<style>
.offers-page .old-price {
  text-decoration: line-through;
}

.offers-page .offers-header {
  word-break: normal !important;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.offers-description {
  word-break: normal !important;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.offers-page .v-input__append-outer {
  margin: 0 !important;
}

.bottom-categories {
  text-align: center;
}
.bottom-categories span {
  text-decoration: underline;
}
.bottom-categories a,
.bottom-categories {
  color: gray;
  text-decoration: none;
}
.bottom-categories a:hover {
  text-decoration: underline;
}
.bottom-categories ul {
  margin: 0;
  padding: 0;
  display: inline;
  list-style: none;
}

.bottom-categories li {
  display: inline;
}

.bottom-categories li:after {
  content: ", ";
}
.bottom-categories li:last-child:after {
  content: "";
}

.offer-search div.v-subheader {
  display: none !important;
}

@media screen and (max-width: 768px) {
  .offer-search .col-12 {
    margin-bottom: 10px;
  }
  .offer-search {
    text-align: right;
  }
}
</style>